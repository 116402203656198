//import { Turbo } from "@hotwired/turbo";
import * as Turbo from "@hotwired/turbo";

export default class TurboTrain extends HTMLElement {
  eventSource!: EventSource;
  static get observedAttributes() {
    return ["href"];
  }

  constructor() {
    super();
  }

  connectedCallback() {
    this.eventSource = new EventSource(this.href);
    Turbo.connectStreamSource(this.eventSource);
  }

  disconnectedCallback() {
    Turbo.disconnectStreamSource(this.eventSource);
  }

  get href() {
    const href = this.getAttribute("href");
    if (!href) {
      throw new Error("The href attribute is not set.");
    }
    return href;
  }
}

if (typeof window !== "undefined" && !window.customElements.get("turbo-train-stream-source")) {
  window.customElements.define("turbo-train-stream-source", TurboTrain);
}
