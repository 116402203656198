import { Notifier } from "@airbrake/browser";

import ahoy from "ahoy.js";

//import "@hotwired/turbo-rails";
import "@hotwired/turbo";

const airbrake = new Notifier({
  //host: "https://err.dsble.de",
  projectId: 19985,
  projectKey: "56b3c0cea61950684f91feb0460308d9",
  //projectKey: "edcdd8563076f741d56cfd05cc2daa44",
  environment: "production",
  //remoteConfig: false,
  //performanceStats: false,
  //queueStats: false,
  //queryStats: false,
});
(window as any).airbrake = airbrake;

import Alpine from "alpinejs";
(window as any).Alpine = Alpine;
import ui from "@alpinejs/ui";
Alpine.plugin(ui);
import focus from "@alpinejs/focus";
Alpine.plugin(focus);
import collapse from "@alpinejs/collapse";
Alpine.plugin(collapse);
import blurhash from "../alpine-blurhash.ts";
Alpine.plugin(blurhash);

Alpine.start();

ahoy.configure({
  visitsUrl: "/bhoy/visits",
  eventsUrl: "/bhoy/events",
});
ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");

// import "@uscreentv/turbo-train";
import "../turbo-train.ts";

function readyAndLoaded() {
  if (navigator.share) {
    Array.from(document.querySelectorAll<HTMLLinkElement>("a.navigatorshare")).forEach((el) => {
      const shareData = {
        title: el.dataset.title,
        text: el.dataset.text,
        url: el.dataset.url,
      };
      const post_id = document.location.pathname.substring(1).match(/^\d{6,9}/)?.[0];
      el.addEventListener("click", async (e) => {
        e.preventDefault();
        ahoy.track("share", { where: "navigator", clicked: true, id: post_id });
        try {
          const res = await navigator.share!(shareData);
          ahoy.track("shared", {
            where: "navigator",
            success: true,
            id: post_id,
            res,
          });
        } catch (err) {
          ahoy.track("shared", {
            where: "navigator",
            success: false,
            id: post_id,
            err,
          });
        }
      });
      el.classList.remove("!hidden");
    });
  }
  Array.from(document.querySelectorAll<HTMLLinkElement>("a.sharing-button:not(.navigatorshare)")).forEach((el) => {
    el.addEventListener("click", async () => {
      ahoy.track("share", {
        where: el.dataset.where,
        clicked: true,
        id: document.location.pathname.substring(1).match(/^\d{6,9}/)?.[0],
      });
    });
  });
}

import { ready } from "../utils.ts";
ready(readyAndLoaded);

document.documentElement.addEventListener("turbo:fetch-request-error", (event) => {
  console.error("TURBO fetch-request-error", event);
});
